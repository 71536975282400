var menu = (function () {
    'use strict';

    return {
        init: function () {
            window.addEventListener('resize', this.setHeight);
            
            var toggle = document.getElementsByClassName('menu-toggle');
            if (toggle.length) {
                toggle[0].addEventListener("click", this.open);
            }

            var dropdowns = document.getElementsByClassName('dropdown-header');
            for(var i =0; i < dropdowns.length; i++){
                dropdowns[i].addEventListener("click", function(){
                    this.classList.toggle("tapped");
                });
            }
        },

        open: function () {
            var body = document.getElementsByTagName('body');
            if(body.length){
                body[0].classList.toggle('menu-open');
            }
        },
        setHeight: function(){
            let vh = window.visualViewport ? window.visualViewport.height : window.innerHeight;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
    }

})();

menu.init();
menu.setHeight();
